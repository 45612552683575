import {
  Button,
  Card,
  CardFooter,
  Checkbox,
  Input,
  Label,
  LargeTitle,
  Select,
  Subtitle1,
} from "@fluentui/react-components";
import React, {
  ChangeEventHandler,
  FC,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { Field } from "@fluentui/react-components";
import { SecurityType, createWifiConfig } from "./qr/wificode";
import WifiCode from "./qr/WifiCodeComponent";
import { validateSSID } from "./qr/validate";

function useInputValue<InputType = HTMLInputElement>() {
  const [value, setValue] = useState<string>("");
  const eventHandler: ChangeEventHandler<InputType> = useCallback((event) => {
    const newVal = (event.target as any).value;
    setValue(newVal);
  }, []);
  return [value, eventHandler, setValue] as const;
}

const Home: FC = () => {
  const [securityType, securityTypeChange] = useInputValue<HTMLSelectElement>();
  const [networkName, networkNameChange] = useInputValue();
  const [password, passwordChange] = useInputValue();
  const [hidden, setHidden] = useInputValue();
  const networkHidden = hidden === "on";

  const SSIDValid = validateSSID(networkName);
  const [ssidHasHighlighted, setSSIDHasHighlighted] = useState(false);

  const [qrcode, setqrcode] = useState<ReactNode>();
  const disabled =
    !securityType ||
    !networkName ||
    (securityType !== SecurityType.NONE && !password) ||
    !SSIDValid;

  const generateQRCode = () => {
    const config = createWifiConfig(
      securityType as SecurityType,
      networkName,
      password !== "" ? password : undefined,
      networkHidden
    );
    setqrcode(
      <WifiCode
        config={config}
        style={{
          width: "200px",
          height: "200px",
        }}
      />
    );
  };

  return (
    <div
      style={{
        maxWidth: 1024,
        margin: "auto",
        marginTop: "100px",
      }}
    >
      <LargeTitle as="h1">Wifi QR Code Generator</LargeTitle>
      <br />
      <Subtitle1 as="h2">
        Generate a QR code to log a friend into your wifi network
      </Subtitle1>
      <Card style={{ marginTop: 20 }}>
        <Field
          label="Security Type"
          validationMessage={"If you don't know, it's probably \"WPA\""}
          validationState="none"
        >
          <Select onChange={securityTypeChange} value={securityType}>
            <option value={undefined}></option>
            <option value={SecurityType.NONE}>No Password</option>
            <option value={SecurityType.WPA}>WPA</option>
            <option value={SecurityType.WEP}>WEP</option>
          </Select>
        </Field>
        <Field
          label="Network Name"
          onBlur={() => setSSIDHasHighlighted(true)}
          validationMessage={
            !SSIDValid && ssidHasHighlighted
              ? "Please enter a valid network name"
              : undefined
          }
        >
          <Input onChange={networkNameChange} value={networkName} />
        </Field>
        {securityType !== SecurityType.NONE && (
          <Field label="Password">
            <Input onChange={passwordChange} value={password} />
          </Field>
        )}
        <Checkbox
          onChange={setHidden}
          label={<Label>Network is hidden</Label>}
          value={hidden}
        />
        <CardFooter>
          <Button onClick={generateQRCode} disabled={disabled}>
            Generate
          </Button>
        </CardFooter>
        {qrcode}
      </Card>
      <div style={{ marginTop: 100 }}>
        <p>
          Welcome to our user-friendly QR code generator for hassle-free Wi-Fi
          network access! Tired of manually typing in complex Wi-Fi passwords?
          Say goodbye to the frustration and say hello to simplicity with our
          online tool. With just a few clicks, you can create a QR code that,
          when scanned, instantly connects your device to the desired Wi-Fi
          network.
        </p>
        <p>
          No more sharing lengthy passwords, and no more worrying about typos.
          We've streamlined the process for you. Whether you're at home, in a
          coffee shop, or visiting a friend's place, our QR code generator makes
          connecting to Wi-Fi networks a breeze.
        </p>
        <p>
          Just enter your network's name (SSID) and password, hit the "Generate"
          button, and voilà! You'll receive a QR code that's ready to be saved
          or printed. It's a convenient solution for guests, friends, and
          family, ensuring they can connect without the hassle of inputting a
          complex password.
        </p>
        <p>
          This tool is compatible with various devices and QR code scanner apps,
          so you can use it on your smartphone, tablet, or laptop. Enjoy
          seamless Wi-Fi access – try our QR code generator today and simplify
          your network connections!
        </p>
      </div>
      <div style={{ marginTop: 100 }}>
        Uses code from{" "}
        <a href="https://github.com/kazuhikoarase/qrcode-generator">
          qrcode-generator
        </a>
      </div>
    </div>
  );
};

export default Home;
