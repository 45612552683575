export enum SecurityType {
  WEP = "WEP",
  WPA = "WPA",
  NONE = "nopass",
}

export interface WifiConfig {
  T: SecurityType;
  S: string;
  P?: string;
  H?: string;
}

export function generateWifiCode(config: WifiConfig) {
  const configString = Object.entries(config)
    .map(([key, value]) => `${key}:${value}`)
    .join(";");
  const wifiString = `WIFI:${configString};;`;
  console.log(wifiString);
  return wifiString;
}

export function createWifiConfig(
  type: SecurityType,
  ssid: string,
  password?: string,
  hidden?: boolean
): WifiConfig {
  const config: WifiConfig = {
    T: type,
    S: ssid,
    P: password,
    H: hidden === undefined ? "" : `${hidden}`,
  };

  return config;
}
