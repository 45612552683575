import React, { CSSProperties, FC, useMemo } from "react";
import { WifiConfig, generateWifiCode } from "./wificode";
import qrGenerator from "qrcode-generator";

interface WifiCodeProps {
  config: WifiConfig;
  style?: CSSProperties;
}

const WifiCode: FC<WifiCodeProps> = ({ config, style }) => {
  const data = generateWifiCode(config);

  const qrcodeURL = useMemo(() => {
    const qr = qrGenerator(0, "L");
    qr.addData(data);
    qr.make();
    return qr.createDataURL(5, 1);
  }, [data]);

  return (
    <div style={style}>
      {/* <QRImage value={data} /> */}
      <img src={qrcodeURL} alt="Wifi QR code" />
    </div>
  );
};

export default WifiCode;
