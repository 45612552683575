import React from "react";
import Home from "./Home";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

function App() {
  return (
    <FluentProvider theme={webLightTheme}>
      <Home />
    </FluentProvider>
  );
}

export default App;
